define('voucherCodes', ['jquery', 'siteObj'], function($, siteObj) {
  
  var _voucherCodes = {

    // All vouchers with timers
    _elementsWithTimer: [],

    // Time out
    _timeout: '',

    /**
     * Constructor run on load
     */
    _init: function() {
      var voucherCodesWrapper = $('[data-component=voucherCodes]');
      this._bindEvents(voucherCodesWrapper);
      this._voucherTimer(voucherCodesWrapper);
      this._updateVoucherTimes();
      this._hideExpiry()
    },

    /**
     * Add events required for script
     * @param {object} voucherCodesWrapper - main voucher class
     */
    _bindEvents: function(voucherCodesWrapper) {
      voucherCodesWrapper.find('.voucher-detail-toggle').click(function() {
        var element = $(this);
        element.next('.voucher-detail-content').slideToggle();
        element.toggleClass('selected');
      });
    },

    /**
     * Set up timers
     * @param {object} voucherCodesWrapper - main voucher class
     */
    _voucherTimer: function(voucherCodesWrapper) {

      var dateArray = siteObj.features.countdown.servertime.split('-');

      var year = parseInt(dateArray[0], 10);
      var month = parseInt(dateArray[1], 10) - 1; //This needs to have 1 removed because in javascript months go from 0 - 11
      var date = parseInt(dateArray[2], 10);
      var hour = parseInt(dateArray[3], 10);
      var minute = parseInt(dateArray[4], 10);
      var second = parseInt(dateArray[5], 10);

      var today = new Date(year, month, date, hour, minute, second, 0); // The time for today build from the server time


      var vouchers = voucherCodesWrapper.find('.voucher-container');

      vouchers.each(function() {
        var element = $(this);
        var endString = element.find('.voucher-end-date');

        // strip Ends
        var endDateString = endString.html();
        var endDate = endDateString.split(': ');
        var voucherEnd = new Date(endDate[1]);


        var triggerDate = new Date(endDate[1]);
        triggerDate.setDate(triggerDate.getDate() - 1);


        if (triggerDate > today) {

          element.find('.voucher-end-date').css('display', 'block');
          element.find('.voucher-end-date-digits').css('display', 'none');
          return;
        }

        /**
         * Remove timer once offer has expired (Offer stays visable due to caching)
         */

        if (voucherEnd < today) {

          element.find('.voucher-end-date-digits').css('display', 'none');
          return;
        }


        element.find('.voucher-end-date').css('display', 'none');
        element.find('.voucher-end-date-digits').css('display', 'block');

        var remainingTime = new Date(triggerDate.getTime() - today.getTime());

        _voucherCodes._elementsWithTimer.push(element);

        var elementInArray = _voucherCodes._elementsWithTimer[_voucherCodes._elementsWithTimer.length - 1];

        elementInArray.differenceHours = remainingTime.getHours();
        elementInArray.differenceMinutes = remainingTime.getMinutes();
        elementInArray.differenceSeconds = remainingTime.getSeconds();

      });
    },

    /**
     * Update vouchers with timer
     */
    _updateVoucherTimes: function() {
      for (var i = 0, elementsWithTimerLength = _voucherCodes._elementsWithTimer.length; i < elementsWithTimerLength; i++) {
        var element = _voucherCodes._elementsWithTimer[i];

        element.find('.voucher-hours').html(element.differenceHours);
        element.find('.voucher-minutes').html(element.differenceMinutes);
        element.find('.voucher-seconds').html(element.differenceSeconds);

        _voucherCodes._updateTimer(element);
      }
      _voucherCodes._timeout = setTimeout(_voucherCodes._updateVoucherTimes, 1000);

    },

    /**
     * Update timer
     * @param {object} element - timer to update
     */
    _updateTimer: function(element) {
      var differenceSeconds = element.differenceSeconds;
      var differenceMinutes = element.differenceMinutes;
      var differenceHours = element.differenceHours;


      if (differenceSeconds <= 60 && differenceSeconds > 0) {
        element.differenceSeconds--;
        element.find('.voucher-seconds').html(differenceSeconds);
      }

      if (differenceSeconds <= 9) {
        element.find('.voucher-seconds').html('0' + differenceSeconds);
      }

      if (differenceSeconds === 0 && differenceMinutes >= 1) {
        element.differenceMinutes--;
        element.find('.voucher-minutes').html(differenceMinutes);
        element.differenceSeconds = 60;
        element.find('.voucher-seconds').html(differenceSeconds);
      }

      if (differenceMinutes <= 9) {
        element.find('.voucher-minutes').html('0' + differenceMinutes);
      }

      if (differenceMinutes === 0 && differenceHours >= 1) {
        element.differenceHours--;
        element.find('.voucher-hours').html(differenceHours);
        element.differenceMinutes = 60;
        element.find('.voucher-minutes').html('0' + differenceMinutes);
      }

      if (differenceHours <= 9) {
        element.find('.voucher-hours').html('0' + differenceHours);
      }

      if (differenceHours === 0 && differenceMinutes === 0 && differenceSeconds === 0) {
        element.find('.voucher-hours').html('00');
        element.find('.voucher-minutes').html('00');
        element.find('.voucher-seconds').html('00');
        clearTimeout(_voucherCodes._timeout);
      }
    },

    //hide if the voucher code expires in more than 2 years
    _hideExpiry: function () {
      var voucherCodesWrapper = $('[data-component=voucherCodes]');
      var vouchers = voucherCodesWrapper.find('.voucher-container');

      vouchers.each( function() {
        var element = $(this);
        var endString = element.find('.voucher-end-date');
        var endDateString = endString.html();
        var endDate = endDateString.split(': ')
        var voucherEnd = new Date(endDate[1]);
    
        var endDateTimeStamp = voucherEnd.getTime();
        var dateNow = new Date();
        var twoYearsFromNow = dateNow.setFullYear(dateNow.getFullYear() + 2); //get two years from todays date

        if (endDateTimeStamp > twoYearsFromNow) {
          endString.css('display', 'none');
          endString.toggleClass('no-timer');
        }

      })
    }
  };
  _voucherCodes._init();

  return _voucherCodes;
});
